import {
    ConvenienceStore,
    InstallmentPlan,
    OnlineBrand,
    PaymentType,
    TemporaryTokenAliasQrLogoType,
    TransactionTokenType,
    UsageLimit,
} from "univapay-node";

import {
    Message,
    MessageChargeCreated,
    MessageClose,
    MessageError,
    MessageExecutePayment,
    MessageSubscriptionCreated,
    MessageSuccess,
    MessageTokenCreated,
    MessageType,
    MessageValidationError,
} from "../../common/Messages";
import {
    CheckoutParams as CommonCheckoutParams,
    CheckoutStyles,
    CheckoutType,
    PatchedCardBrand,
    Period,
} from "../../common/types";
import { development } from "../../common/utils/development";

import { Connector } from "./Connector";

export interface CheckoutParams {
    appId?: string;
    checkout?: CheckoutType;
    onSuccess?: (...params: any[]) => void;
    onPending?: (...params: any[]) => void;
    onError?: (...params: any[]) => void;
    onTokenCreated?: (...params: any[]) => void;
    onChargeCreated?: (...params: any[]) => void;
    onSubscriptionCreated?: (...params: any[]) => void;
    onValidationError?: (...params: any[]) => void;
    beforeClosing?: () => boolean;
    opened?: () => void;
    closed?: () => void;
    autoClose?: boolean;
    autoCloseOnError?: boolean;
    autoSubmit?: boolean;
    autoSubmitOnError?: boolean;
    amount?: number;
    capture?: boolean;
    captureAt?: string;
    captureIn?: Period;
    captureDayOfMonth?: number;
    confirmationRequired?: boolean;
    currency?: string;
    dark?: boolean;
    locale?: string;
    title?: string;
    header?: string;
    description?: string;
    address?: boolean;
    requireEmail?: boolean;
    requireBillingAddress?: boolean;
    requirePhoneNumber?: boolean;
    tokenType?: TransactionTokenType;
    usageLimit?: UsageLimit;
    subscriptionPeriod?: string;
    subscriptionId?: string;
    subscriptionInitialAmount?: number;
    subscriptionStart?: string;
    subscriptionStartIn?: Period;
    subscriptionStartDayOfMonth?: number;
    subscriptionPlan?: InstallmentPlan;
    subscriptionQty?: number;
    installmentPlan?: InstallmentPlan;
    installmentQty?: number;
    showCvv?: boolean;
    univapayCustomerId?: string;
    subscriptionTimezone?: string;
    subscriptionPreserveEndOfMonth?: boolean;
    subscriptionRetryInterval?: string;
    allowCardInstallments?: boolean;
    qrColor?: string;
    qrLogoType?: TemporaryTokenAliasQrLogoType;
    paymentType?: PaymentType | OnlineBrand;
    paymentTypes?: (PaymentType | OnlineBrand | PatchedCardBrand | ConvenienceStore)[];
    paymentMethods?: (PaymentType | OnlineBrand | PatchedCardBrand | ConvenienceStore)[];
    products?: string[];
    hasBeforeClosingCallback?: boolean;
    hideRecurringCheckbox?: boolean;
    hidePrivacyLink?: boolean;
}

type EventType =
    | "opened"
    | "closed"
    | "success"
    | "error"
    | "form-error"
    | "pending"
    | "token-created"
    | "charge-created"
    | "subscription-created"
    | "validation-error";
type LegacyEventType = "callback";

export interface CheckoutConstructorParams extends CheckoutParams {
    appId: string;
}
export class Checkout {
    static create(params: CheckoutConstructorParams & Partial<CheckoutParams>): Checkout {
        return new Checkout(params);
    }

    static async submit(iframe: HTMLIFrameElement) {
        if (!iframe) {
            throw new Error("No element provided to `submit`. Please provide the payment iframe element.");
        }

        const connector = Connector.getConnectorByIFrame(iframe);
        if (!connector) {
            throw new Error("No connector found for the element. Please provide the payment iframe element.");
        }

        return connector.submit();
    }

    params: CheckoutConstructorParams;
    connector: Connector;

    private execute: Message<MessageExecutePayment>;

    constructor(params: CheckoutConstructorParams) {
        this.params = { ...params, hasBeforeClosingCallback: !!params.beforeClosing };
        this.connector = new Connector();

        this.connector.emitter.on("checkout:opened", this.handleOpened);
        this.connector.emitter.on("checkout:closed", this.handleClosed);
        this.connector.emitter.on("checkout:success", this.handleSuccess);
        this.connector.emitter.on("checkout:pending", this.handlePending);
        this.connector.emitter.on("checkout:error", this.handleError);
        this.connector.emitter.on("checkout:form-error", this.handleFormError);
        this.connector.emitter.on("checkout:token-created", this.handleTokenCreated);
        this.connector.emitter.on("checkout:charge-created", this.handleChargeCreated);
        this.connector.emitter.on("checkout:subscription-created", this.handleSubscriptionCreated);
        this.connector.emitter.on("checkout:validation-error", this.handleValidationError);
    }

    open = async (_params?: CheckoutParams & CheckoutStyles, parentNode?: HTMLElement) => {
        const params = { ...this.params, ..._params } as CommonCheckoutParams & CheckoutStyles;
        this.execute = {
            data: { connectorId: undefined, params, appId: params.appId, checkout: params.checkout },
            type: MessageType.EXECUTE,
        };

        try {
            const checkout = await this.connector.open(this.execute, parentNode);
            development(() => console.info("6- Executing checkout..."));
            this.connector.emitter.emit("checkout:execute", this.execute);

            return checkout;
        } catch (error) {
            development(() => console.error(error));
        }
    };

    dispatchCheckoutEvent<PayloadType>(type: EventType | LegacyEventType, params?: PayloadType) {
        const legacyEvent = new CustomEvent(`gopay:${type}`, params);
        const openEvent = new CustomEvent(`univapay:${type}`, params);

        window.dispatchEvent(legacyEvent);
        window.dispatchEvent(openEvent);
    }

    handleOpened = (): void => {
        this.dispatchCheckoutEvent("opened");
        this.params.opened?.();
    };

    handleClosed = (message: MessageClose): void => {
        this.dispatchCheckoutEvent("closed");

        if (message?.failedSubscriptionId) {
            this.params.subscriptionId = message.failedSubscriptionId;
        }

        this.connector.close();
        this.params.closed?.();
    };

    handleSuccess = (message: MessageSuccess): void => {
        this.dispatchCheckoutEvent("success", { detail: message });
        this.dispatchCheckoutEvent("callback", { detail: message }); // Dispatch the `callback` event for backward compatibility
        this.params.onSuccess?.(message);
    };

    handlePending = (message: MessageSuccess): void => {
        this.dispatchCheckoutEvent("pending", { detail: message });
        this.params.onPending?.(message);
    };

    handleError = (message: MessageError): void => {
        this.dispatchCheckoutEvent("error", { detail: message });
        this.params.onError?.(message);
    };

    handleFormError = (message: MessageClose): void => {
        this.dispatchCheckoutEvent("form-error");

        if (message?.failedSubscriptionId) {
            this.params.subscriptionId = message.failedSubscriptionId;
        }

        this.connector.close();
        this.params.closed?.();
    };

    handleTokenCreated = (message: MessageTokenCreated): void => {
        this.dispatchCheckoutEvent("token-created", { detail: message });
        this.params.onTokenCreated?.(message);
    };

    handleChargeCreated = (message: MessageChargeCreated): void => {
        this.dispatchCheckoutEvent("charge-created", { detail: message });
        this.params.onChargeCreated?.(message);
    };

    handleSubscriptionCreated = (message: MessageSubscriptionCreated): void => {
        this.dispatchCheckoutEvent("subscription-created", { detail: message });
        this.params.onSubscriptionCreated?.(message);
    };

    handleValidationError = (message: MessageValidationError): void => {
        this.dispatchCheckoutEvent("validation-error", { detail: message });
        this.params.onValidationError?.(message);
    };
}
