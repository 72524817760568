import { ResponseCharge, ResponseSubscription, ResponseTransactionToken } from "univapay-node";

import { CheckoutParams } from "./types";

export interface EnhancedMessageEvent extends MessageEvent {
    originalEvent: { origin: string };
}

export type Message<Data, Code = MessageType> = {
    type: Code;
    data?: Data;
    errors?: Error[];
};

export enum ResourceType {
    TRANSACTION_TOKEN = "transactionToken",
    CHARGE = "charge",
    SUBSCRIPTION = "subscription",
}

export enum MessageType {
    HANDSHAKE = "handshake",
    EXECUTE = "execute",
    OPENED = "opened",
    BEFORE_CLOSING = "before-closing",
    BEFORE_CLOSING_RESPONSE = "before-closing-response",
    CLOSED = "closed",
    SUCCESS = "success",
    PENDING = "pending",
    ERROR = "error",
    RESIZE = "resize",
    SUBMIT_CARD_DATA = "submit-card-data",
    SUBMITTED = "submitted",
    TOKEN_CREATED = "token-created",
    CHARGE_CREATED = "charge-created",
    SUBSCRIPTION_CREATED = "subscription-created",
    VALIDATION_ERROR = "validation-error",
}

export type ConnectorMessage =
    | Message<MessageHandshake, MessageType.HANDSHAKE>
    | Message<MessageOpened, MessageType.OPENED>
    | Message<MessageExecute, MessageType.EXECUTE>
    | Message<MessageResize, MessageType.RESIZE>
    | Message<MessageSubmitted, MessageType.SUBMITTED>
    | Message<MessageSuccess, MessageType.SUCCESS>
    | Message<Record<string, unknown>, MessageType.PENDING>
    | Message<MessageError, MessageType.ERROR>
    | Message<MessageClose, MessageType.CLOSED>
    | Message<MessageBeforeClosing, MessageType.BEFORE_CLOSING>
    | Message<MessageBeforeClosingResponse, MessageType.BEFORE_CLOSING_RESPONSE>
    | Message<MessageTokenCreated, MessageType.TOKEN_CREATED>
    | Message<MessageChargeCreated, MessageType.CHARGE_CREATED>
    | Message<MessageSubscriptionCreated, MessageType.SUBSCRIPTION_CREATED>
    | Message<MessageValidationError, MessageType.VALIDATION_ERROR>;

export interface BaseMessageType {
    connectorId: string;
}

export type MessageHandshake = BaseMessageType & {
    paired: boolean;
    pendingExec?: boolean;
};

export type MessageOpened = BaseMessageType;

export type MessageExecute = BaseMessageType & {
    checkout: string;
    appId: string;
    params: CheckoutParams;
};

export type MessageResize = BaseMessageType & {
    height: string;
};

export type MessageSubmitted = BaseMessageType & {
    token: string;
};

export type MessageSuccess = BaseMessageType & {
    resourceType: ResourceType;
    response: ResourceResponse;
    tokenId: string;
    chargeId?: string;
    subscriptionId?: string;
};

export type MessageError = BaseMessageType & {
    error: unknown;
    resourceType?: ResourceType;
    response?: ResourceResponse;
    tokenId: string;
    chargeId?: string;
    subscriptionId?: string;
};

export type MessageClose = BaseMessageType & { failedSubscriptionId?: string };

export type MessageBeforeClosingResponse = BaseMessageType & { shouldClose: boolean };
export type MessageBeforeClosing = BaseMessageType;

export type MessageTokenCreated = BaseMessageType;

export type MessageChargeCreated = BaseMessageType;

export type MessageSubscriptionCreated = BaseMessageType;

export type MessageExecutePayment = MessageExecute;

export type MessageValidationError = BaseMessageType;

export type ResourceResponse = ResponseTransactionToken | ResponseCharge | ResponseSubscription;

export const isChargeResponse = (response: ResourceResponse): response is ResponseCharge =>
    (response as ResponseCharge).requestedAmount !== undefined;
