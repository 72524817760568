import * as ERRORS_LABELS from "../labels/errors";

export const LABELS = {
    [ERRORS_LABELS.ERRORS_TITLE]: "Errors setting up checkout",
    [ERRORS_LABELS.ERRORS_CHECKOUT]: "Could not connect to checkout service.",
    [ERRORS_LABELS.ERRORS_NO_SCRIPT_ID]: "Checkout script not embedded correctly.",
    [ERRORS_LABELS.ERRORS_NO_APP_ID]: "An application token is required.",
    [ERRORS_LABELS.ERRORS_NO_CHECKOUT]: "A checkout type is required and must be either 'token' or 'payment'",
    [ERRORS_LABELS.ERRORS_NOT_PAYMENT_CHECKOUT]: "A checkout type is required and must be set to 'payment'",
    [ERRORS_LABELS.ERRORS_SUBSCRIPTION_NOT_ALLOWED_FOR_ONLINE]: "When using subscription, the payment types can not contain online types",
    [ERRORS_LABELS.ERRORS_INVALID_TOKEN_TYPE]: "Token type must be 'one_time', 'subscription', or 'recurring'.",
    [ERRORS_LABELS.ERRORS_INVALID_AMOUNT]: "A positive amount is required.",
    [ERRORS_LABELS.ERRORS_INVALID_DATE]: "Invalid date format.",
    [ERRORS_LABELS.ERRORS_INVALID_CURRENCY]: "Currency is invalid.",
    [ERRORS_LABELS.ERRORS_INVALID_CAPTURE_IN]: "Invalid period provided to the 'capture in' parameter.",
    [ERRORS_LABELS.ERRORS_INVALID_SUBSCRIPTION_START_IN]: "Invalid period provided to the 'subscription start in' parameter.",
    [ERRORS_LABELS.ERRORS_INVALID_SUBSCRIPTION_RETRY_INTERVAL]: "Invalid period provided to the 'subscription retry interval' parameter.",
    [ERRORS_LABELS.ERRORS_INVALID_SUBSCRIPTION_PERIOD]: "A subscription period must be an ISO8601 period, 'daily', 'weekly', 'biweekly', 'monthly', 'bimonthly', 'quarterly', 'semiannually', or 'annually'.",
    [ERRORS_LABELS.ERRORS_INVALID_LOCALE]: "Locale must be 'auto' or one of the supported languages.",
    [ERRORS_LABELS.ERRORS_INVALID_USAGE_LIMIT]: "Usage limit must be 'daily', 'weekly', 'monthly', or 'yearly'.",
    [ERRORS_LABELS.ERRORS_UNNECESSARY_AMOUNT]: "When providing products the amount parameter must be omitted.",
    [ERRORS_LABELS.ERRORS_UNNECESSARY_CURRENCY]: "When providing products the currency parameter must be omitted.",
    [ERRORS_LABELS.ERRORS_UNNECESSARY_SUBSCRIPTION_ID]: "When providing a subscription ID, the token type must be 'subscription'.",
    [ERRORS_LABELS.ERRORS_UNNECESSARY_INSTALLMENT_PLAN]: "When providing a installment plan, the token type must be 'subscription'.",
    [ERRORS_LABELS.ERRORS_UNNECESSARY_INSTALLMENT_PLAN_QTY]: "When providing a installment plan parameter, the token type must be 'subscription'.",
    [ERRORS_LABELS.ERRORS_NO_SUBSCRIPTION_PERIOD]: "A subscription period is required.",
    [ERRORS_LABELS.ERRORS_TITLE_TOO_LONG]: "Title can be a maximum of 64 characters.",
    [ERRORS_LABELS.ERRORS_INVALID_INSTALLMENT_PLAN]: "Installment plan must be 'revolving' or 'fixed_cycles'.",
    [ERRORS_LABELS.ERRORS_INVALID_SUBSCRIPTION_PLAN]: "Installment plan must be 'fixed_cycles' or 'fixed_cycle_amount'.",
    [ERRORS_LABELS.ERRORS_INVALID_INSTALLMENT_AMOUNT]: "A positive amount is required.",
    [ERRORS_LABELS.ERRORS_INVALID_INSTALLMENT_INITIAL_AMOUNT]: "A positive initial amount is required.",
    [ERRORS_LABELS.ERRORS_INVALID_CUSTOMER_ID]: "Customer ID must be a UUID.",
    [ERRORS_LABELS.ERRORS_INVALID_TIMEZONE]: "The timezone is invalid",
    [ERRORS_LABELS.ERRORS_INVALID_PAYMENT_TYPE]: "Payment type must be one of apple_pay, card, konbini, paidy, bank_transfer, pay_pay_online, we_chat_online, alipay_online or online (all online brands)",
    [ERRORS_LABELS.ERRORS_INVALID_PAYMENT_TYPES]: "Some payment types are not supported. Available: apple_pay, card, konbini, paidy, bank_transfer, pay_pay_online, we_chat_online, alipay_online, alipay_plus or online (all online brands)",
    [ERRORS_LABELS.ERRORS_INVALID_INSTALLMENTS]: "Some installment cycles are not supported. Available: 1, 3, 5, 6, 10, 12, 15, 18, 20, 24 and revolving",
    [ERRORS_LABELS.ERRORS_INVALID_PRODUCTS]: "Products should be a list of uuids separated with a ','",
    [ERRORS_LABELS.ERRORS_INVALID_AUTH_CODE]: "The auth code is not correct",
    [ERRORS_LABELS.ERRORS_INVALID_COLOR_CODE]: "The Color code must be 3 or 6 hexadecimal digits starting with '#'",
    [ERRORS_LABELS.ERRORS_INVALID_LOGO_TYPE]: "Logo type must be 'Background', 'Centered', or 'None'",
    [ERRORS_LABELS.ERRORS_INVALID_SHIPPING_ADDRESS]: "The shipping address is invalid",
    [ERRORS_LABELS.ERRORS_INVALID_SHIPPING_ADDRESS_COUNTRY_CODE]: "The address's country code is invalid",
    [ERRORS_LABELS.ERRORS_INVALID_SHIPPING_ADDRESS_ZIP]: "The address's zip code is invalid",
    [ERRORS_LABELS.ERRORS_EXPIRATION_TIMESHIFT]: "The expiration time shift is invalid",
    [ERRORS_LABELS.ERRORS_EXPIRATION_PERIOD]: "The expiration period is invalid",
    [ERRORS_LABELS.ERRORS_PAIDY_ZIP_CODE_REQUIRED]: "Zip code value is required for Paidy payment type",
    [ERRORS_LABELS.ERRORS_INVALID_METADATA]: "Metadata should be a record of string, number or boolean.",
    [ERRORS_LABELS.ERRORS_GENERIC]: "Invalid parameters provided.",
    [ERRORS_LABELS.ERRORS_INVALID_ALLOW_INSTALLMENTS_CHECKOUT]: "When providing the 'allowCardInstallments' property, the checkout must of 'token' or 'payment'.",
    [ERRORS_LABELS.ERRORS_INVALID_ALLOW_INSTALLMENTS_TOKEN_TYPE]: "When providing the 'allowCardInstallments' property, the token type must of 'one_time' or 'recurring'.",
    [ERRORS_LABELS.ERRORS_INVALID_ALLOW_INSTALLMENTS_USAGE_LIMIT]: "When providing the 'allowCardInstallments' property, the recurring usage limit should be ignored.",
    [ERRORS_LABELS.ERRORS_NO_CUSTOM_FIELD_KEY]: "A key is required in custom field.",
    [ERRORS_LABELS.ERRORS_NO_CUSTOM_FIELD_LABEL]: "A label is required in custom field.",
    [ERRORS_LABELS.ERRORS_NO_CUSTOM_FIELD_TYPE]: "A type is required in custom field. It can be 'select' or 'string'",
    [ERRORS_LABELS.ERRORS_NO_CUSTOM_FIELD_OPTIONS]: "A list of options is required in 'select' custom field.",
    [ERRORS_LABELS.ERRORS_INVALID_THREE_DS_MODE]: "The 3DS mode must be 'normal', 'force' or 'skip'.",
};

export default LABELS;
