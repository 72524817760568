import { Checkout } from "./Checkout";
import { WindowElement } from "./WindowElement";

let loaded = false;
const create: typeof Checkout.create = Checkout.create;
const submit: typeof Checkout.submit = Checkout.submit;

const init = async () => {
    loaded = true;

    window.UnivapayCheckout.loaded = true;
    window.GopayCheckout.loaded = true;
};

const storeInWindow = () => {
    const checkoutPublicParams = { create, loaded, submit };
    window.UnivapayCheckout = checkoutPublicParams;
    window.GopayCheckout = checkoutPublicParams;
};

if (!loaded) {
    storeInWindow();

    WindowElement.createWindowElements(true);
    init();
}
