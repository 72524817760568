import { SupportedLocale } from "common/locale/labels";
import { NewCardBrand } from "common/types";
import { CardBrand, ConvenienceStore, OnlineBrand, PaymentType, SubscriptionPeriod, UsageLimit } from "univapay-node";
import * as Yup from "yup";

const durationRegexp = new RegExp("P[\\d]+(D|W|M|Y)", "i");

declare module "yup" {
    interface StringSchema {
        paymentMethod(options: { allowOnline?: boolean }, message: string): Yup.StringSchema;
        cardInstallment(message: string): Yup.StringSchema;
        usageLimit(message: string): Yup.StringSchema;
        subscriptionPeriod(message: string): Yup.StringSchema;
        duration(message: string): Yup.StringSchema;
        locale(message: string): Yup.StringSchema;
        timeShift(message: string): Yup.StringSchema;
    }

    interface MixedSchema {
        falsyWhen(condition: boolean, message?: string): Yup.MixedSchema;
        falsyWhenOrRequired(condition: boolean, message?: string): Yup.MixedSchema;
    }

    interface ObjectSchema<
        TIn extends Yup.AnyObject,
        TContext = Yup.AnyObject,
        TDefault = any,
        TFlags extends Yup.Flags = ""
    > {
        metadata(message?: string): Yup.ObjectSchema<TIn, TContext, TDefault, TFlags>;
    }
}

function isFalsyWhen(condition: boolean, message?: string) {
    return condition ? this.test("prohibited", message, (value) => !value) : undefined;
}
Yup.addMethod(Yup.mixed, "falsyWhen", isFalsyWhen);

function isFalsyWhenOrRequired(condition: boolean, message?: string) {
    return condition ? this.test("prohibited", message, (value) => !value) : this.required();
}
Yup.addMethod(Yup.mixed, "falsyWhenOrRequired", isFalsyWhenOrRequired);

function isUuid(message: string) {
    return this.matches("[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}", message);
}
Yup.addMethod(Yup.string, "uuid", isUuid);

function isPaymentMethod(options: { allowOnline?: boolean }, message?: string) {
    const { allowOnline = true } = options || {};
    return this.oneOf(
        [
            ...Object.values(PaymentType).filter((value) => (allowOnline ? true : value !== PaymentType.ONLINE)),
            ...(allowOnline ? [...Object.values(OnlineBrand), "d_barai_online"] : []),
            ...Object.values(CardBrand),
            ...Object.values(NewCardBrand),
            ...Object.values(ConvenienceStore),
        ],
        message
    );
}
Yup.addMethod(Yup.string, "paymentMethod", isPaymentMethod);

function isCardInstallmentValue(message: string) {
    return this.oneOf(["1", "3", "5", "6", "10", "12", "15", "18", "20", "24", "revolving"], message);
}
Yup.addMethod(Yup.string, "cardInstallment", isCardInstallmentValue);

function usageLimit(message: string) {
    return this.oneOf(
        [UsageLimit.DAILY, UsageLimit.MONTHLY, UsageLimit.WEEKLY, "yearly", UsageLimit.ANNUALLY],
        message
    );
}
Yup.addMethod(Yup.string, "usageLimit", usageLimit);

function isSubscriptionPeriod(message: string) {
    return this.test("is-subscription-period", message, (value) => {
        return (
            !value ||
            [
                SubscriptionPeriod.BIWEEKLY,
                SubscriptionPeriod.WEEKLY,
                SubscriptionPeriod.DAILY,
                SubscriptionPeriod.ANNUALLY,
                SubscriptionPeriod.MONTHLY,
                SubscriptionPeriod.BIMONTHLY,
                SubscriptionPeriod.QUARTERLY,
                SubscriptionPeriod.SEMIANNUALLY,
            ].includes(value) ||
            durationRegexp.test(value)
        );
    });
}
Yup.addMethod(Yup.string, "subscriptionPeriod", isSubscriptionPeriod);

function isDuration(message: string) {
    return this.matches(/P[\d]+(D|W|M|Y)/, message);
}
Yup.addMethod(Yup.string, "duration", isDuration);

function isTimeShift(message: string) {
    return this.matches(/(\d{2}:\d{2}:\d{2}(\.\d{3})?)((\+|-)(\d{2}:\d{2})|Z)/, message);
}
Yup.addMethod(Yup.string, "timeShift", isTimeShift);

function isMetadata(message: string) {
    const isMetadataLeaf = (value: unknown) =>
        !value || ["number", "string", "boolean", "bigint"].includes(typeof value) || value instanceof BigInt;

    return this.test(
        "is-metadata",
        message,
        (value) =>
            !value ||
            (typeof value === "object" &&
                Object.values(value).every(
                    (value) => isMetadataLeaf(value) || (Array.isArray(value) && value.every(isMetadataLeaf))
                ))
    );
}
Yup.addMethod(Yup.object, "metadata", isMetadata);

function isLocale(message?: string) {
    return this.test("is-locale", message, (value: string) => {
        const lowerCasedValue = value?.toLowerCase();

        if (!lowerCasedValue || lowerCasedValue === "auto") {
            return true;
        }

        return Object.values(SupportedLocale).some((locale) => {
            const lowerCasedLocale = locale.toLowerCase();

            return lowerCasedValue === lowerCasedLocale || lowerCasedValue === lowerCasedLocale.slice(0, 2);
        });
    });
}
Yup.addMethod(Yup.string, "locale", isLocale);
